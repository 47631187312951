import React, { useState } from 'react'
// import ReCAPTCHA from "react-google-recaptcha";
import { TextField, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import validate from './validateLoginInfo';
import useFormLogin from './useFormLogin';

const useStyles = makeStyles((theme) => ({
  formButton:{
    "& ":{
      fontFamily: 'Barlow Condensed !important',
      backgroundColor: "#013e7e !important",
      width: 'fit-content',
      borderRadius: '10px',
      fontSize: '18px',
      marginBottom: "5px"
    },
    "& .MuiButton-label":{
      justifyContent: "center",
      padding: "10px",
      fontWeight: "600 !important",
      backgroundColor: "#013e7e",
      border: "0",
      width: "150px",
    }

  },
  formInput: {
    "&":{
      marginBottom: "5px"
    },
    "& .MuiFormLabel-root":{
      color: "#013e7e",
    },
    "& .MuiInputBase-root ":{
      backgroundColor: "#b3d3f5",
      color: "#013e7e",
      outlineWidth: "0",
      width: "350px",
    }, 
    "& .MuiFilledInput-root": {
      "& input": { 
          borderColor: "#91ddf7"
      },
      "&.MuiFilledInput-underline:before": {
        borderBottom: "none"
      },
      "&.MuiFilledInput-underline:after": {
          borderBottom: "2px solid #013e7e"
      }
    },
}}));

function FormLogin(props) {
  const { handleChange, handleSubmit, values, errors, messages, setIsSubmitting, isSubmitting } = useFormLogin(
    props.submitForm,
    validate
  );

  const classes = useStyles();

  const [authToken] = useState(props.authenticity_token);
  // const [recaptchaSiteKey] = useState(props.recaptcha_site_key);
  // const recaptchaRef = React.createRef();

  const changeURL = (url) =>{
    window.location.href = url;
  }

  const submitForm = async (e) =>{
    e.preventDefault();
    console.log("submit form");
    console.log("🌹 ");
    setIsSubmitting(true);
    // const token = await recaptchaRef.current.executeAsync();
    // handleSubmit(e, props.authenticity_token, token);
    handleSubmit(e, props.authenticity_token)
  }
  console.log("MESSAGES");
  console.log(messages);
  return (
    <div className="formLogin">
      {errors.error && <div className="form-error">{errors.error}</div>}
      <form onSubmit={submitForm}>
        <input type="hidden" value={authToken}/>
        
        <TextField id="filled-basic" label="Email" className={classes.formInput} name="email" error={errors.email !== undefined} helperText={errors.email !== "" ? errors.email : ' '} value={values.email} onChange={handleChange} variant="filled" />

        <TextField id="filled-basic" name="password"  type="password" label="Password" className={classes.formInput} value={values.password} onChange={handleChange} error={errors.password !== undefined} helperText={errors.password !== "" ? errors.password : ' '} variant="filled" />
        <br/>
        {isSubmitting ? 
          (<div ><CircularProgress /><span>{messages.button ? messages.button :''}</span></div>)
          :
          <Button variant="contained" type="submit" color="primary" className={classes.formButton}>
            LOG IN
          </Button>
        }
        
        {/* <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={recaptchaSiteKey}
        /> */}
        <a href="/user/passwords/forgot_password">Oops, I forgot my password</a>  
        <a href="/user/new">I need to set up an online account</a>
      </form>
    </div>
  )
}

export default FormLogin
