import React, { useState } from 'react'
import { TextField, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import validate from './validateSignUpInfo';
import useFormSignUp from './useFormSignUp';

const useStyles = makeStyles((theme) => ({
  
  formButton:{
    "& ":{
      fontFamily: 'Barlow Condensed !important',
      backgroundColor: "#013e7e !important",
      width: 'fit-content',
      borderRadius: '10px',
      fontSize: '18px',
      marginBottom: "5px"
    },
    "& .MuiButton-label":{
      justifyContent: "left",
      padding: "10px",
      fontWeight: "600 !important",
      backgroundColor: "#013e7e",
      border: "0"
    }
  },
  formInput: {
    "&":{
      marginBottom: "5px"
    },
    "& .MuiFormLabel-filled":{
      color: "#013e7e",
    },
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
    "& .MuiInputBase-root ":{
      backgroundColor: "#b3d3f5",
      color: "#013e7e",
      outlineWidth: "0",
      width: "350px",
    }, 
    "& .MuiFilledInput-root": {
      "& input": { 
          borderColor: "#91ddf7"
      },
      "&.MuiFilledInput-underline:before": {
        borderBottom: "none"
      },
      "&.MuiFilledInput-underline:after": {
          borderBottom: "2px solid #013e7e"
      }
    },
}}));

function FormSignUp(props) {
  const { handleChange, handleSubmit, values, errors, messages, setIsSubmitting, isSubmitting } = useFormSignUp(
    props.submitForm,
    validate
  );

  const classes = useStyles();

  const [authToken] = useState(props.authenticity_token);
  // const [recaptchaSiteKey] = useState(props.recaptcha_site_key);
  // const recaptchaRef = React.createRef();

  const submitForm = async (e) =>{
    e.preventDefault();
    console.log("submit form");
    console.log("🌹 ");
    setIsSubmitting(true);
    // const token = await recaptchaRef.current.executeAsync();
    // handleSubmit(e, token, props.authenticity_token);
    handleSubmit(e, props.authenticity_token);
  }
  
  return (
    <div className="formSignUp" >
      {errors.error && <div className="form-error">{errors.error}</div>}
      <form onSubmit={submitForm}>
        <input type="hidden" value={authToken}/>
        <TextField id="filled-basic" label="Zip Code" className={classes.formInput} name="zipCode" value={values.zipCode} onChange={handleChange} error={errors.zipCode !== undefined} helperText={errors.zipCode !== "" ? errors.zipCode : ' '} variant="filled" />

        <TextField id="filled-basic" label="Email" className={classes.formInput} name="email" error={errors.email !== undefined} helperText={errors.email !== "" ? errors.email : ' '} value={values.email} onChange={handleChange} variant="filled" />

        <TextField id="filled-basic" type="password" label="Password" className={classes.formInput} value={values.password} name="password" value={values.password} onChange={handleChange} error={errors.password !== undefined} helperText={errors.password !== "" ? errors.password : ' '} variant="filled" 
        />

        <TextField id="filled-basic" type="password" label="Retype Password" className={classes.formInput} value={values.rePassword} name="rePassword" value={values.rePassword} onChange={handleChange} error={errors.rePassword !== undefined} helperText={errors.rePassword !== "" ? errors.rePassword : ' '} variant="filled" />
        <br/>
        {isSubmitting ? 
          (<div><CircularProgress /><span>{messages.button ? messages.button :''}</span></div>)
          :
          <Button variant="contained" type="submit" color="primary" className={classes.formButton} >
            CREATE ACCOUNT
          </Button>
        }
        <br/>
        <a href="/user/login">Go Back to Login</a>
      </form>
    </div>
  )
}

export default FormSignUp
