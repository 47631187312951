import { useState, useEffect } from 'react';
import Axios from 'axios';

const useFormLogin = (callback, validate) => {
  const [values, setValues] = useState({
    email: '',
    password: ''
  });

  const [errors, setErrors] = useState({});
  const [messages, setMessages] = useState({ button: 'This will take a moment...' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const changeURL = (url) => {
    window.location.replace(url);
  }

  const handleChange = e => {
    console.log(e.target);
    console.log("HANDLE CHANGE---");
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const loginUser = (authToken, token) => {
    console.log("--- INSIDE CREATE USER ---");
    Axios.post('/user/sessions',
      {
        email: values.email,
        password: values.password,
        authenticity_token: authToken,
        recaptcha_token: token
      }
    ).then((response) => {
      console.log('⛓ ')
      console.log(response);
      changeURL(response.data.redirect_url);
    }, (error) => {
      console.log('🐇 ')
      console.log(error);
      console.log(error.response);
      setErrors(error.response.data.errors);
      setIsSubmitting(false);
    });
  }

  const handleSubmit = async (e, authTok, tok = '') => {
    console.log("IN SUBMIT--- 🧷 ");
    console.log(tok);
    let validation_errors = await validate(values);
    console.log("BEFORE SET ERRORS");
    console.log(errors);
    console.log("VALID ERRORS");
    console.log(validation_errors);
    let await_errors = await setErrors(validation_errors);
    console.log("AFTER SET ERRORS");
    console.log(errors);
    console.log("AWAIT ERRORs");
    console.log(await_errors);
    if (Object.keys(validation_errors).length === 0) {
      loginUser(authTok, tok);
      setMessages({ button: 'success, redirecting...' });
    } else {
      setIsSubmitting(false);
    }
  };

  useEffect(
    () => {
      if (Object.keys(errors).length === 0 && !isSubmitting) {
        callback();
      }
    },

    [errors]
  );

  return { handleChange, handleSubmit, values, errors, messages, setIsSubmitting, isSubmitting };
};

export default useFormLogin;
